<template>
  <div id="demographicData">
    <h5>Demographic Data</h5>
    <hr />

    <div class="text-center mt-4 mb-3" v-if="getDataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
      <br />
      <label> Please Wait </label>
    </div>

    <notification
      v-if="
        notification.isActive &&
          content.demographicData.patientConfirmedDiagnosisOfPompe === false
      "
      :type="notification.type"
      :message="notification.message"
    />

    <notification
      v-if="!content.demographicData.datePatientDataProcessingConsentSigned"
      type="info"
      message="Consent not provided/revoked"
    />

    <b-overlay :show="saveDataLoading">
      <form @submit.prevent="requestSaveForm" v-if="getDataSuccess">
        <!-- Patient ID -->
        <text-field
          v-if="handleLogicStatement('patientRef', 'visibility')"
          v-model="content.demographicData.patientRef"
          label="Patient ID"
          name="patientRef"
          placeholder="Patient ID"
          :isDisable="true"
          :logic="logic"
          :required="true"
        />

        <!-- Date Patient Consent Signed -->
        <datepicker
          v-if="handleLogicStatement('datePatientConsentSigned', 'visibility')"
          label="Date Patient Consent Signed"
          name="datePatientConsentSigned"
          placeholder="Enter Date Patient Consent Signed"
          v-model="content.demographicData.datePatientConsentSigned"
          :mandatory="true"
          :logic="logic"
          :errors="errors"
          :isDisable="handleLogicStatement('datePatientConsentSigned', 'disable')"
          @changeEvent="updateData"
        />

        <!-- Date patient consent for processing of health-related personal data -->
        <datepicker
          v-if="handleLogicStatement('datePatientDataProcessingConsentSigned', 'visibility')"
          label="Date Patient Data Processing Consent Signed"
          name="datePatientDataProcessingConsentSigned"
          placeholder="Enter Date patient consent for processing of health-related personal data"
          v-model="content.demographicData.datePatientDataProcessingConsentSigned"
          :logic="logic"
          :errors="errors"
          :isDisable="handleLogicStatement('datePatientDataProcessingConsentSigned', 'disable')"
          @changeEvent="updateData"
        />

        <!-- Health Insurance -->
        <dropdown
          v-if="handleLogicStatement('healthInsurance', 'visibility')"
          v-model="content.demographicData.healthInsurance"
          label="Health Insurance"
          name="healthInsurance"
          :options="options.healthInsuranceOpt"
          :logic="logic"
          :errors="errors"
          @changeEvent="updateData"
        />

        <!-- Year of Birth -->
        <yearpicker
          v-if="handleLogicStatement('yearOfBirth', 'visibility')"
          v-model="content.demographicData.yearOfBirth"
          label="Year Of Birth"
          name="yearOfBirth"
          :mandatory="true"
          :content="content"
          :yearRange="options.yearRange"
          :logic="logic"
          :errors="errors"
          @changeEvent="updateData"
        />

        <!-- Height, Weight-->
        <div class="row">
          <div
            class="col"
            v-if="handleLogicStatement('patientWeight', 'visibility')"
          >
            <decimal-input
              v-model="content.demographicData.patientWeight"
              label="Weight (kg)"
              name="patientWeight"
              placeholder="Weight (kg)"
              tooltipMessage="Data entered here represents data at baseline (inclusion of patient). Changes throughout the course of participation are tracked in the section vital signs"
              :mandatory="true"
              :logic="logic"
              :errors="errors"
              @blurEvent="updateData"
            />
          </div>
          <div
            class="col"
            v-if="handleLogicStatement('patientHeight', 'visibility')"
          >
            <decimal-input
              v-model="content.demographicData.patientHeight"
              label="Height (cm)"
              type="double"
              name="patientHeight"
              placeholder="Height (cm)"
              tooltipMessage="Data entered here represents data at baseline (inclusion of patient). Changes throughout the course of participation are tracked in the section vital signs"
              :mandatory="true"
              :logic="logic"
              :errors="errors"
              @blurEvent="updateData"
            />
          </div>
        </div>

        <!-- Patient Gender -->
        <radiobutton
          v-if="handleLogicStatement('patientGender', 'visibility')"
          v-model="content.demographicData.patientGender"
          label="Patient Gender"
          name="patientGender"
          :options="options.genderOpt"
          :mandatory="true"
          :logic="logic"
          :errors="errors"
          @inputEvent="updateData"
        />

        <!-- Patient Confirmed Diagnosis of Pompe -->
        <radiobutton
          v-if="
            handleLogicStatement(
              'patientConfirmedDiagnosisOfPompe',
              'visibility'
            )
          "
          v-model="content.demographicData.patientConfirmedDiagnosisOfPompe"
          label="Patient Confirmed Diagnosis of Pompe"
          name="patientConfirmedDiagnosisOfPompe"
          :mandatory="true"
          :options="[
            { text: 'Yes', value: true },
            { text: 'No', value: false }
          ]"
          :logic="logic"
          :errors="errors"
          @inputEvent="updateData"
        />

        <div class="buttons mt-3 text-center" v-show="getDataSuccess">
          <button class="btn btn-sm btn-pompe-primary" type="submit">
            Continue
          </button>
        </div>
      </form>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DemographicDataMixin from "./DemographicData.mixin";

export default {
  mixins: [DemographicDataMixin],
  data: () => {
    return {
      loading: false,
      getDataLoading: false,
      getDataSuccess: false,
      updateDataLoading: false,
      saveDataLoading: false,
      content: null,
      logic: null,
      errors: null,
      options: null,
      notification: {
        type: "",
        message: "",
        isActive: false
      }
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    ...mapActions({
      setPatientRef: 'creation/reqPatientRef',
    }),
    handleGetDataSuccess(response) {
      this.adjustTab(response.data.nav);
      this.checkFormStatus(null, "demographicData", "GET", response.data.nav);

      this.content = response.data.content;
      this.logic = response.data.logic;
      this.options = response.data.options;

      this.setPatientRef(this.content.demographicData.patientRef);

      this.dateFormatter();
      this.checkPompePatient();
      this.getDataSuccess = true;
    },

    // PUT Request, Update Data
    async updateData(fieldName = null) {
      this.updateDataLoading = true;

      // Remove highlighted error if any
      if (this.errors) {
        if (fieldName) this.removeError(fieldName);
      }

      this.dateFormatter();
      await this.requestUpdateForm(); // PUT Request here
      this.checkPompePatient();
    }
  }
};
</script>
