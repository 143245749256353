<template>
  <div id="surgicalProcedure">
    <h5>Surgical Procedure</h5>

    <hr />

    <div class="text-center mt-4 mb-3" v-if="getDataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
      <br />
      <label> Please Wait </label>
    </div>

    <notification
      v-if="notification.isActive"
      :type="notification.type"
      :message="notification.message"
    />

    <notification
      v-if="
        getDataSuccess &&
        handleLogicStatement('surgicalProcedureInfo', 'visibility')
      "
      type="info"
      :message="infoText"
    />

    <b-overlay :show="saveDataLoading">
      <fieldset :disabled="!canManagePatient">
        <form
          class="mt-2"
          @submit.prevent="requestSaveForm"
          v-if="getDataSuccess"
        >
          <!-- alreadyHadSurgicalProcedure-->
          <radiobutton
            v-if="handleLogicStatement('alreadyHadSurgicalProcedure', 'visibility')"
            v-model="content.surgicalProcedures.alreadyHadSurgicalProcedure"
            label="Did the patient already had a surgical procedure ?"
            name="alreadyHadSurgicalProcedure"
            :mandatory="true"
            :content="content"
            :options="options.alreadyHadSurgicalProcedureOpt"
            :logic="logic"
            :errors="errors"
            @inputEvent="updateData"
          />

          <h6 v-if="content.surgicalProcedures.alreadyHadSurgicalProcedure == 'yes'">List of Procedures</h6>
          <hr v-if="content.surgicalProcedures.alreadyHadSurgicalProcedure == 'yes'" />
          <div
            v-show="content.surgicalProcedures.alreadyHadSurgicalProcedure == 'yes'"
            class="mt-3"
            v-for="(item, idx) in content.surgicalProcedures.procedures"
            :key="idx"
          >
            <h6>
              Procedure {{ `#${idx + 1}` }}
              <span v-if="idx > 0">
                <a
                  class="text-danger ml-2"
                  href="javascript:void(0)"
                  @click="deleteProcedure(idx)"
                >
                  <b-icon icon="trash"></b-icon>
                </a>
              </span>
            </h6>

            <!-- Surgical Procedure -->
            <text-field
              v-if="handleLogicStatement('surgicalProcedure', 'visibility', { index: idx })"
              v-model="content.surgicalProcedures.procedures[idx].surgicalProcedure"
              label="Surgical Procedure"
              name="surgicalProcedure"
              :index="idx"
              placeholder="Surgical Procedure"
              parent="procedures"
              :logic="logic"
              :errors="errors"
              @blurEvent="updateData"
            />

            <!-- Side -->
            <dropdown
              v-if="handleLogicStatement('side', 'visibility', { index: idx })"
              v-model="content.surgicalProcedures.procedures[idx].side"
              label="Side"
              name="side"
              :index="idx"
              parent="procedures"
              :options="options.sideListOpt"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />

            <!-- Is Surgical Procedure Date Unknown -->
            <checkbox
              v-if="handleLogicStatement('isDateUnknown', 'visibility', { index: idx })"
              v-model="content.surgicalProcedures.procedures[idx].isDateUnknown"
              name="isDateUnknown"
              label="Is Date of Surgical Procedures Unkown ?"
              type="switch"
              :index="idx"
              parent="procedures"
              :logic="logic"
              :errors="errors"
              @inputEvent="updateData"
            />

            <!-- Date of Surgical Procedure -->
            <text-field
              v-if="handleLogicStatement('surgicalProcedureDate', 'visibility', { index: idx })"
              v-model="content.surgicalProcedures.procedures[idx].surgicalProcedureDate"
              label="Date of Surgical Procedure"
              name="surgicalProcedureDate"
              :index="idx"
              placeholder="Enter Date of Surgical Procedure"
              parent="procedures"
              :logic="logic"
              :errors="errors"
              @blurEvent="updateData"
            />

            <!-- procedurePriorToPompeTherapy -->
            <dropdown
              v-if="handleLogicStatement('procedurePriorToPompeTherapy', 'visibility', { index: idx })"
              v-model="content.surgicalProcedures.procedures[idx].procedurePriorToPompeTherapy"
              label="Was the procedure prior to initiation of Pompe therapy?"
              name="procedurePriorToPompeTherapy"
              :index="idx"
              parent="procedures"
              :options="options.procedureListOpt"
              :logic="logic"
              :errors="errors"
              @changeEvent="updateData"
            />
          </div>

          <!-- button to add another procedure -->
          <div class="buttons">
            <button
              v-if="content.surgicalProcedures.alreadyHadSurgicalProcedure == 'yes'"
              type="button"
              class="btn btn-sm btn-link"
              @click="addProcedure"
            >
              <b-icon icon="plus" aria-hidden="true"></b-icon>
              Add another surgical procedure
            </button>
          </div>

          <div v-if="canManagePatient" class="buttons mt-3 text-center">
            <button class="btn btn-sm btn-pompe-primary ml-2" type="submit">
              Continue
            </button>
          </div>
        </form>
      </fieldset>
    </b-overlay>
  </div>
</template>

<script>
import SurgicalProcedure from './SurgicalProcedure.mixin';
import { $getInfoText } from '@/helper/globalFunction';

export default {
  props: {
    userData: {
      type: Object,
      default: () => ({})
    },
    canManagePatient: {
      type: Boolean,
      default: false,
    }
  },
  mixins: [
    SurgicalProcedure
  ],
  data: () => {
    return {
      infoText: null,
      getDataLoading: false,
      getDataSuccess: false,
      updateDataLoading: false,
      saveDataLoading: false,
      content: null,
      logic: null,
      errors: null,
      options: null,
      notification: {
        type: "",
        message: "",
        isActive: false,
      },
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    $getInfoText,
    async addProcedure() {
      await this.content.surgicalProcedures.procedures.push({
        dateOfProcedureUnkown: false,
        surgicalProcedureDate: "",
        surgicalProcedureInfo: null,
        side: null,
        procedurePriorToPompeTherapy: null,
      });
      this.updateData();
    },

    async deleteProcedure(idx) {
      await this.content.surgicalProcedures.procedures.splice(idx, 1);
      this.updateData();
    },

    handleGetDataSuccess(response) {
      this.adjustTab(response.data.nav);
      this.checkFormStatus(
        null,
        "surgicalProcedures",
        "GET",
        response.data.nav,
      );

      this.content = response.data.content;
      this.logic = response.data.logic;
      this.options = response.data.options;

      this.infoText = this.$getInfoText(
        "surgicalProcedureInfo",
        this.options.info
      );

      this.getDataSuccess = true;
    },

    // Update form data
    updateData(fieldName = null) {
      this.updateDataLoading = true;

      // Remove highlighted error if any
      if (this.errors) {
        if (fieldName) this.removeError(fieldName);
      }

      this.requestUpdateForm();
    },
  },
};
</script>
