<template>
  <div id="patientDiagnosis">
    <h5>Patient Diagnosis</h5>

    <hr />

    <div class="text-center mt-4 mb-3" v-if="getDataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
      <br />
      <label> Please Wait </label>
    </div>

    <notification
      v-if="notification.isActive"
      :type="notification.type"
      :message="notification.message"
    />

    <!-- form start -->
    <b-overlay :show="saveDataLoading">
      <fieldset :disabled="!canManagePatient">
        <form @submit.prevent="requestSaveForm" v-if="getDataSuccess">
          <!-- Date of pompe diagnosis -->
          <datepicker 
            v-model="content.patientDiagnosis.dateOfPompeDiagnosis"
            label="Date of Pompe Diagnosis"
            name="dateOfPompeDiagnosis"
            placeholder="Enter Date of Pompe Diagnosis"
            :mandatory="true"
            :logic="logic"
            :errors="errors"
            :isDisable="handleLogicStatement('dateOfPompeDiagnosis', 'disable')"
            @changeEvent="updateData"
          />

          <enzyme-forms 
            display-form="first"
            :bypass-logic="true"
            :content="content"
            :options="options"
            :updateData="updateData"
            :errors="errors"
            :logic="logic"
            :updateFieldVal="updateFieldValue"
          />

          <radiobutton 
            v-if="handleLogicStatement('methodOfPompeDiagnosis', 'visibility')"
            v-model="content.patientDiagnosis.methodOfPompeDiagnosis"
            label="Method of Pompe Diagnosis"
            name="methodOfPompeDiagnosis"
            description="If the patient's GAA enzyme activity is available, please complete the patient Diagnosis - GAA Enzyme Activity CRF"
            :options="[
              { text: 'None', value: 'oneEnzymeAssay' },
              { text: '2 Enzymes Assay', value: 'twoEnzymeAssay' },
              { text: '1 Enzymes Assay and Genotype', value: 'oneEnzymeAssayAndGenotype' },
            ]"
            :logic="logic"
            :errors="errors"
            @inputEvent="updateData"
          />

          <component
            v-show="content.patientDiagnosis.methodOfPompeDiagnosis !== null"
            :is="translateComponent(content.patientDiagnosis.methodOfPompeDiagnosis)"
            display-form="second"
            :content="content"
            :options="options"
            :updateData="updateData"
            :errors="errors"
            :logic="logic"
            :updateFieldVal="updateFieldValue"
          ></component>

          <div v-if="canManagePatient" class="buttons mt-3 text-center">
            <button class="btn btn-sm btn-pompe-primary" type="submit">
              Continue
            </button>
          </div>
        </form>
      </fieldset>
    </b-overlay>
    <!-- form end -->
  </div>
</template>

<script>
const enzymeForms = () => import("./PD-children/EnzymesForm.vue");
const enzymeAndGenotype = () => import("./PD-children/EnzymeAndGenotype.vue");

import PatientDiagnosisMixin from './PatientDiagnosis.mixin';

export default {
  name: 'PatientDiagnosis',
  props: {
    userData: {
      type: Object,
      default: () => ({}),
    },
    canManagePatient: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    'enzyme-forms':enzymeForms,
    'enzyme-and-genotype':enzymeAndGenotype,
  },
  mixins: [
    PatientDiagnosisMixin
  ],
  data: () => {
    return {
      getDataLoading: false,
      getDataSuccess: false,
      updateDataLoading: false,
      saveDataLoading: false,
      content: null,
      logic: null,
      errors: null,
      options: null,
      notification: {
        type: "",
        message: "",
        isActive: false,
      },
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    translateComponent(value) {
      if (value === 'twoEnzymeAssay') {
        return 'enzyme-forms';
      } else if ( value === 'oneEnzymeAssayAndGenotype'){
        return 'enzyme-and-genotype';
      }

      return null;
    },
    handleGetDataSuccess(response) {
      this.adjustTab(response.data.nav);
      this.checkFormStatus(
        null,
        "patientDiagnosis",
        "GET",
        response.data.nav,
      );

      this.content = response.data.content;
      this.logic = response.data.logic;
      this.options = response.data.options;

      this.dateFormatter();
      this.getDataSuccess = true;
    },

    // Update data
    updateData(fieldName = null) {
      this.updateDataLoading = true;

      // Remove highlighted error if any
      if (this.errors) {
        if (fieldName) this.removeError(fieldName);
      }

      this.dateFormatter();
      this.requestUpdateForm();
    },
  },
};
</script>

<style lang="scss" scoped>
// #patientDiagnosis {
//   height: 100vh !important;
// }
</style>
